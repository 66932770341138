<template>
  <div class="video-container">
    <video class="payment-video" autoplay muted playsinline>
      <source src="@/assets/paymentCanceled.webm" type="video/webm" />
      Tu navegador no soporta el formato de video `.webm`.
    </video>
    <h2>Pago Rechazado</h2>
    <p>Hubo un problema al procesar el pago</p>
    <div class="order-total">
      <span>Total de la Orden:</span>
      <span class="value">$ {{ total }}</span>
    </div>
    <div class="buttons-container">
      <v-btn
        @click="
          $router.push({
            path: '/menu/weborders/payment',
            query: $route.query,
          })
        "
        color="#0bc6ff"
        class="retry-button px-5 text-white"
        block
        depressed
        rounded
        x-large
      >
        Volver a intentar
      </v-btn>

      <v-btn
        @click="cancelOrder"
        outlined
        color="#0bc6ff"
        class="cancel-button px-5"
        block
        rounded
        x-large
      >
        Cancelar orden
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentCanceled",
  data() {
    return {
      total: null,
    };
  },
  methods: {
    async cancelOrder() {
      //Reseteo los valores de la orden para el proximo usuario
      await this.cleanDeliveryOrder();

      //Redirijo a weborders
      this.$router.push({
        name: "MenuWeborders",
        query: { commerceId: this.$route.query.commerceId },
      });
    },
    async cleanDeliveryOrder() {
      const { commerceId, table } = this.$route.query;
      const cleanDeliveryPayload = {
        commerceId,
        deliveryUuid: table,
      };
      await this.$store.dispatch(
        "deliveryCart/cleanDeliveryOrder",
        cleanDeliveryPayload
      );
    },
  },
  mounted() {
    this.total = this.$route.query.total;
  },
};
</script>

<style scoped>
.video-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  font-family: Arial, sans-serif;
  margin-top: 0;
}

.payment-video {
  width: 300px;
  height: 300px;
  margin-bottom: 0;
}

h2 {
  font-size: 1.5em;
  margin-top: 0px;
  margin-bottom: 10px;
  color: #333;
}

p {
  font-size: 1em;
  margin-top: 15px;
  margin-bottom: 25px;
  color: #666;
}

.order-total {
  margin-top: 20px;
  background-color: #efefef;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
  font-size: 1.2sem;
  font-weight: bold;
}

.value {
  margin-left: 120px;
}

.buttons-container {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 300px;
}

.retry-button {
  background-color: #0bc6ff;
  color: white;
  font-size: 1em;
}

.cancel-button {
  border: 2px solid #0bc6ff;
  color: #0bc6ff;
  font-weight: bold;
  font-size: 1em;
}
</style>
